<template>
	<div class="business-create">
		<w-navTab titleText="发布商圈"></w-navTab>
		<div class="container">
		<div class="head-form">
			<div class="head-input">
				<input type="text" v-model="title" placeholder="请输入标题" />
			</div>
			<div class="head-input">
				<input
					type="text"
					readonly
					v-model="groupText"
					@click="isShowSelectGroup = true"
					placeholder="请选择行业类型"
				/>
				<van-icon name="arrow" size="20px" color="#AAA" />
			</div>
		</div>
		<div class="head-top" v-if="addressId">
			<div class="head-middle">
				<div class="head-top-left">
					<div class="head-top-text">{{ addressAddress }}</div>
					<div class="head-top-title">{{ addressName }}</div>
					<div class="head-top-text">{{ addressTelephone }}</div>
				</div>
				<div class="head-top-right" @click="$router.push({ name: 'BusinessAddress' })">
					<div class="right-text">切换地址</div>
					<van-icon name="arrow" size="20px" color="#AAA" />
				</div>
			</div>
			<div class="head-top-bottom">
				<div class="head-top-bottom-picture">
					<img src="@/assets/img/icon500.png" alt="" />
				</div>
			</div>
		</div>
		<div
			class="head-empty-address"
			v-if="!addressId"
			@click="$router.push({ name: 'BusinessAddress' })"
		>
			<van-icon name="add-o" />
			<div class="head-empty-address-text">选择地址</div>
		</div>

		<div class="middle-text">
			<textarea placeholder="请输入内容" v-model="content"></textarea>
			<div class="middle-footer-left">
				<div>({{ content.length }}/1000)</div>
			</div>
			<div class="uploader">
				<div class="uploader-item" v-for="(vo, index) in picture">
					<img
						class="uploader-close"
						src="@/assets/img/uploader_icon_close.png"
						@click="deletePicture(index)"
					/>
					<img class="uploader-image" :src="vo.url" />
				</div>
				<div class="uploader-item uploader-item-select">
					<van-uploader
						:after-read="onSelect"
						v-show="isShowUploader"
						upload-icon="plus"
					></van-uploader>
				</div>
			</div>
		</div>
		<div class="create-footer">
			<div class="footer">
				<div class="footer-left">
					<div class="footer-left-text">消耗</div>
					<div class="footer-left-title">{{needLoveValue}}爱心积分</div>
				</div>
				<div class="footer-btn" @click="onSubmit">立即发布</div>
			</div>
		</div>
		<van-popup v-model="isShowSelectGroup" position="bottom">
			<van-picker
				title="请选择"
				show-toolbar
				value-key="title"
				:columns="groupList"
				@confirm="onConfirmGroup"
				@cancel="onCancelGroup"
			/>
		</van-popup>
		</div>
	</div>
</template>

<script>
import Event from '@/store/event';
import UploadApi from '@/api/upload';
import keepMiXin from '@/mixins/keep';
import BusinessApi from '@/api/business';
import VantVendor from '@/vendor/vant';
import ValidatorVendor from '@/vendor/validator';

export default {
	name: 'BusinessCreate',
	mixins: [keepMiXin(['BusinessAddress'])],
	data() {
		return {
			picture: [],
			title: '',
			content: '',
			groupList: [],
			groupId: '',
			groupText: '',
			isShowUploader: true,
			isShowSelectGroup: false,
			addressId: '',
			addressName: '',
			addressAddress: '',
			addressTelephone: '',
			needLoveValue: 0,
		};
	},
	watch: {
		content(newVal, oldVal) {
			if (newVal.length > 1000) {
				this.content = this.content.substring(0, 1000);
			}
		},
		picture(newVal, oldVal) {
			this.isShowUploader = newVal.length < 4;
		}
	},
	created() {
		Event.$off(['onSelectAddress', 'onDeleteAddress']);

		// 监听选择地址
		Event.$on('onSelectAddress', address => {
			this.addressId = address.id;
			this.addressName = address.name;
			this.addressAddress = address.address;
			this.addressTelephone = address.telephone;
		});

		// 监听删除
		Event.$on('onDeleteAddress', address => {
			if (address.id == this.addressId) {
				this.addressId = '';
				this.addressName = '';
				this.addressAddress = '';
				this.addressTelephone = '';
			}
		});

		VantVendor.Toast.loading('加载中');

		let getDetaultApi = BusinessApi.getDefaultAddress();
		let getGroupApi = BusinessApi.group();
		let getLoveValueApi = BusinessApi.postLoveValue();

		Promise.all([getDetaultApi, getGroupApi, getLoveValueApi])
			.then(result => {
				this.addressId = result[0].data.id;
				this.addressName = result[0].data.name;
				this.addressAddress = result[0].data.address;
				this.addressTelephone = result[0].data.telephone;
				this.groupList = result[1].data;
				this.needLoveValue = result[2].data.love_value;
			})
			.catch(error => {
				VantVendor.Toast.fail(error.msg);
			})
			.finally(() => {
				VantVendor.Toast.clear();
			});

		this.validator = new ValidatorVendor({
			rules: {
				title: {
					required: true
				},
				group_id: {
					required: true
				},
				address_id: {
					required: true
				},
				content: {
					required: true
				},
				picture: {
					required: true
				}
			},
			messages: {
				title: {
					required: '标题不能为空'
				},
				group_id: {
					required: '行业类型不能为空'
				},
				address_id: {
					required: '店铺地址不能为空'
				},
				content: {
					required: '内容不能为空'
				},
				picture: {
					required: '图片不能为空'
				}
			}
		});
	},
	methods: {
		onConfirmGroup(selected) {
			this.onCancelGroup();
			this.groupId = selected.id;
			this.groupText = selected.title;
		},
		onCancelGroup() {
			this.isShowSelectGroup = false;
		},
		onSubmit() {
			let params = {
				title: this.title,
				content: this.content,
				group_id: this.groupId,
				address_id: this.addressId,
				picture: this.picture.map(item => item.path).join()
			};

			if (!this.validator.checkData(params)) {
				return;
			}

			BusinessApi.addPost(params)
				.then(result => {
					VantVendor.Dialog({ title: '提示', message: '添加成功' }).then(() =>
						this.$router.back()
					);
				})
				.catch(error => {
					VantVendor.Toast.fail(error.msg);
				});
		},
		onSelect(selected) {
			VantVendor.Toast.loading('上传中');

			UploadApi.image(selected.file)
				.then(result => {
					this.picture.push({
						url: result.data.fileUrl,
						path: result.data.filePath
					});
				})
				.catch(error => {
					VantVendor.Toast.fail(error.msg);
				})
				.finally(() => {
					VantVendor.Toast.clear();
				});
		},
		deletePicture(index) {
			this.picture.splice(index, 1);
		}
	}
};
</script>

<style scoped lang="less">
.business-create {
	position: relative;
	.container{
		padding: 10px;
		box-sizing: border-box;
		.head-form {
			.head-input {
				padding: 10px;
				background-color: #fff;
				border-radius: 6px;
				margin-bottom: 10px;
				position: relative;
		
				input {
					display: block;
					line-height: 26px;
					border: 0;
					background-color: #fff;
					padding: 0;
					box-sizing: border-box;
					width: 100%;
					font-size: 16px;
				}
		
				.van-icon {
					position: absolute;
					right: 0;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}
		
		.head-top {
			padding: 10px;
			padding-bottom: 0;
			background-color: #fff;
			border-radius: 6px;
			.head-middle {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-bottom: 12px;
				.head-top-left {
					.head-top-text {
						font-size: 14px;
						line-height: 28px;
						color: #666;
					}
					.head-top-title {
						font-size: 18px;
						line-height: 26px;
						color: #000;
					}
				}
				.head-top-right {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					.right-text {
						font-size: 14px;
						line-height: 26px;
						color: #666;
					}
				}
			}
		
			.head-top-bottom {
				margin-top: -20px;
				height: 13px;
				.head-top-bottom-picture {
					width: 100%;
					height: 2px;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}
		
		.head-empty-address {
			width: 100%;
			height: 60px;
			line-height: 60px;
			background-color: #fff;
			border-radius: 6px;
			display: flex;
			justify-content: center;
			align-items: center;
		
			.head-empty-address-text {
				margin-left: 5px;
				font-size: 16px;
				color: #666;
			}
		
			.van-icon {
				font-size: 18px;
				color: #666;
			}
		}
		
		.middle-text {
			width: 100%;
			height: 100%;
			padding: 10px;
			background-color: #fff;
			box-sizing: border-box;
			border-radius: 6px;
			margin-top: 10px;
			position: relative;
		
			textarea {
				width: 100%;
				height: 224px;
				background-color: #fff;
				border: 0;
				padding: 0;
				font-size: 16px;
			}
		
			.middle-footer-left {
				position: absolute;
				right: 20px;
				top: 239px;
				font-size: 14px;
				line-height: 26px;
				color: #666;
			}
		
			.uploader {
				display: flex;
				margin-top: 36px;
		
				.uploader-item {
					position: relative;
					margin-right: 12px;
		
					&:last-child {
						margin-right: 0;
					}
		
					.uploader-close {
						position: absolute;
						top: 5px;
						right: 5px;
						width: 16px;
						height: 16px;
					}
		
					.uploader-image {
						width: 75px;
						height: 75px;
						object-fit: cover;
						border-radius: 6px;
					}
				}
		
				.uploader-item-select {
					position: relative;
		
					/deep/ .van-uploader__upload {
						width: 75px;
						height: 75px;
						margin: 0;
						border-radius: 6px;
						background-color: #f2f2f2;
		
						.van-icon {
							font-size: 30px;
							color: #aaa;
						}
					}
				}
			}
		}
		
		.create-footer {
			position: absolute;
			left: 0;
			bottom: 10px;
			padding: 0 10px;
			width: 100%;
			box-sizing: border-box;
		
			.footer {
				padding: 10px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				background: #fff;
				border-radius: 6px;
		
				.footer-left {
					display: flex;
					justify-content: flex-start;
					align-items: center;
		
					.footer-left-text {
						font-size: 14px;
						line-height: 26px;
						color: #666;
					}
		
					.footer-left-title {
						font-size: 14px;
						line-height: 26px;
						color: #3377ff;
						font-weight: bold;
					}
				}
		
				.footer-btn {
					padding: 3px 16px;
					font-size: 14px;
					line-height: 26px;
					color: #fff;
					background: #3377ff;
					border-radius: 100px;
				}
			}
		}
	}

}
</style>
